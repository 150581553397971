import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../Layout';
import SEO from '../SEO';
import Button from '../Button';

import styles from './index.module.scss';

const SuccessPage = ({ location, metaTitle, title, content }) => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <Layout location={location} navAlwaysFixed>
      <SEO title={metaTitle} />
      <section className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <FontAwesomeIcon fixedWidth icon={['fal', 'check-circle']} />
          </div>
          <h1>{title}</h1>
          {content}
          <div className={styles.button}>
            <Button to="/">返回主頁</Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SuccessPage;
