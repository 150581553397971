import React from 'react';
import SuccessPage from '../components/SuccessPage';

const CheckoutSuccess = () => {
  return (
    <SuccessPage metaTitle="購買訂倉" title="感謝您的訂單" content={<p>我們的服務主任將於24小時內電郵聯絡您。</p>} />
  );
};

export default CheckoutSuccess;
